import styles from './Stats.module.scss';

import Typography from '@shared/components/atoms/Typography';
import typeStats from '@/utilities/types/Stats';
import cn from 'classnames';

interface StatsProps {
  stats: typeStats;
  style?: 'bold' | 'normal';
}

const Stats = (props: StatsProps) => {
  const { stats, style } = props;
  return (
    <div className={cn(style === 'normal' && styles.normal, styles.root)}>
      <Typography
        className={style === 'bold' ? styles.typography : styles.purple}
        family="noto-sans"
        weight={style === 'bold' ? 'bold' : 'medium'}
        xs={7}
        md={8}
        lg={9}
      >
        {stats.number}
      </Typography>

      {style === 'bold' && <hr className={styles[stats.border]} />}

      <Typography className={styles.typography} xs={3}>
        {stats.title}
      </Typography>
    </div>
  );
};

export default Stats;
