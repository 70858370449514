import styles from './Testimonials.module.scss';

import cn from 'classnames';
import dynamic from 'next/dynamic';
import typeTestimonial from '@/utilities/types/Testimonial';
import { Fragment, useMemo } from 'react';
import { useEffect, useState } from 'react';
import Icons from '@/utilities/helpers/Icons';
import Testimonial from '@/components/molecules/Testimonial';
import useMediaQuery from '@/utilities/hooks/useMediaQuery';
import ChevronDown from '@shared/icons/maggie/ChevronDown';

const Button = dynamic(() => import('@shared/components/atoms/Button'));
const Image = dynamic(() => import('next/image'));

interface TestimonialsProps {
  testimonials: {
    testimonial: typeTestimonial;
  }[];
  design?: string;
}

const Testimonials = (props: TestimonialsProps) => {
  const { testimonials, design } = props;

  const categories = useMemo(() => {
    return testimonials
      .map((testimonial) => testimonial?.testimonial?.media?.[0]?.title ?? null)
      .filter((category) => category);
  }, [testimonials]);

  const [isActive, setIsActive] = useState<number | null>(0);

  const handleTabChange = (index: number) => {
    setIsActive(index);
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    const index = categories.indexOf(value);
    setIsActive(index);
  };

  return (
    <div
      className={cn(
        testimonials.length === 1 && styles.singleTestimonial,
        design && styles[design],
        styles.root,
      )}
    >
      {/* BEGIN DESKTOP TABS */}
      {testimonials.length > 1 && (
        <ul className={styles.tabs}>
          {testimonials.map((testimonial, index) => (
            <li key={index}>
              {testimonial.testimonial.media.map((media, subindex) => (
                <Fragment key={subindex}>
                  {media.icon && design === 'icons' && (
                    <Button
                      className={cn(
                        styles.iconTab,
                        isActive === index && styles['is-active'],
                      )}
                      onClick={() => handleTabChange(index)}
                      size="large"
                      style="secondary"
                      type="button"
                    >
                      <i>
                        {Icons({ icon: media.icon, width: 22, height: 22 })}
                      </i>
                      {media.title}
                    </Button>
                  )}

                  {media.logo && media.logo.logo && design === 'simple' && (
                    <Button
                      className={cn(isActive === index && styles['is-active'])}
                      onClick={() => handleTabChange(index)}
                      size="large"
                      style="secondary"
                      type="button"
                    >
                      <Image
                        alt={media.logo.logo.altText}
                        className={cn(styles.logo, styles.mobile)}
                        height={60}
                        src={media.logo.logo.mediaItemUrl}
                        width={120}
                      />
                    </Button>
                  )}
                </Fragment>
              ))}
            </li>
          ))}
        </ul>
      )}
      {/* END DESKTOP TABS */}

      {/* BEGIN MOBILE DROPDOWN */}
      {categories.length > 1 && (
        <div className={styles.mobileDropdown}>
          <select onChange={(e) => handleSelectChange(e)}>
            {categories.map((category, index) => {
              return category ? (
                <option value={category} key={index}>
                  {category}
                </option>
              ) : (
                <></>
              );
            })}
          </select>
          <ChevronDown />
        </div>
      )}
      {/* END MOBILE DROPDOWN */}

      {/* BEGIN TESTIMONIALS */}
      {testimonials.map((testimonial, index) => (
        <div
          className={cn(isActive === index && styles['is-active'], styles.tab)}
          key={index}
        >
          <Testimonial testimonial={testimonial.testimonial} design={design} />
        </div>
      ))}
      {/* END TESTIMONIALS */}
    </div>
  );
};

export default Testimonials;
